import { useMutation, useSubscription } from '@apollo/client/index.js';
import KanbanBoard from '@asaprint/asap/components/KanbanBoard.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import TasksFilter, {
  Filter,
  filterTasks,
  initialFilter,
  shouldFilter,
} from '@asaprint/asap/components/TasksFilter.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authenticated from '@asaprint/asap/decorators/authenticated.js';
import { onMoveHelper } from '@asaprint/asap/helpers/planning.js';
import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  PlanningGraphicDesign_Load,
  PlanningGraphicDesign_SaveColumns,
  PlanningGraphicDesign_TaskCreated,
  PlanningGraphicDesign_TaskMove,
  PlanningGraphicDesign_TaskUpdated,
} from '@asaprint/asap/routes/__authenticated/planning/graphic-design.graphql';
import {
  PlanningGraphicDesign_LoadQuery,
  PlanningGraphicDesign_LoadQueryVariables,
  PlanningGraphicDesign_SaveColumnsMutation,
  PlanningGraphicDesign_SaveColumnsMutationVariables,
  PlanningGraphicDesign_TaskCreatedSubscription,
  PlanningGraphicDesign_TaskCreatedSubscriptionVariables,
  PlanningGraphicDesign_TaskFragment,
  PlanningGraphicDesign_TaskMoveMutation,
  PlanningGraphicDesign_TaskMoveMutationVariables,
  PlanningGraphicDesign_TaskUpdatedSubscription,
  PlanningGraphicDesign_TaskUpdatedSubscriptionVariables,
  PlanningGraphicDesign_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { hasPermission, Permission, Role } from '@asaprint/common/access.js';
import { emptyArray } from '@asaprint/common/helpers/array.js';
import { isScheduledToday, isScheduledTomorrow } from '@asaprint/common/helpers/OrderReceived.js';
import Loading from '@engined/client/components/Loading.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertTitle, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/pages/planning/PlanningGraphicDesign');

interface OwnProps {}

type Props = OwnProps;

type Task = PlanningGraphicDesign_TaskFragment;

const GraphicDesign: React.FunctionComponent<Props> = () => {
  const { requestUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const canManageSettings = hasPermission(requestUser.permissions as Permission[], Permission.SettingsManage);
  const { loading, data, error, refetch } = useQuery<
    PlanningGraphicDesign_LoadQuery,
    PlanningGraphicDesign_LoadQueryVariables
  >(PlanningGraphicDesign_Load);

  const [taskMoveExecute] = useMutation<
    PlanningGraphicDesign_TaskMoveMutation,
    PlanningGraphicDesign_TaskMoveMutationVariables
  >(PlanningGraphicDesign_TaskMove);

  const [saveColumnsExecute] = useMutation<
    PlanningGraphicDesign_SaveColumnsMutation,
    PlanningGraphicDesign_SaveColumnsMutationVariables
  >(PlanningGraphicDesign_SaveColumns);

  const [customFilter] = useUserSettings<Filter>('PlanningGraphicDesign__customFilter', initialFilter);

  const usersEdges = data?.users?.edges;
  useSubscription<
    PlanningGraphicDesign_TaskUpdatedSubscription,
    PlanningGraphicDesign_TaskUpdatedSubscriptionVariables
  >(PlanningGraphicDesign_TaskUpdated, {
    skip: !usersEdges,
    async onData(opts) {
      try {
        if (usersEdges.some((u) => u.node.id === opts.data.data.taskUpdated.userId)) {
          await refetch();
        }
      } catch (err) {
        logger.error(err);
      }
    },
  });

  useSubscription<
    PlanningGraphicDesign_TaskCreatedSubscription,
    PlanningGraphicDesign_TaskCreatedSubscriptionVariables
  >(PlanningGraphicDesign_TaskCreated, {
    skip: !usersEdges,
    async onData(opts) {
      try {
        if (usersEdges.some((u) => u.node.id === opts.data.data.taskCreated.userId)) {
          await refetch();
        }
      } catch (err) {
        logger.error(err);
      }
    },
  });

  const planningGraphicDesignUsersOrderSettings = data?.settings;
  const todayTasks = useMemo<number>(
    () =>
      usersEdges
        ?.map((e) => e.node)
        ?.reduce(
          (acc, cur) =>
            acc + cur.tasks.filter((t) => isScheduledToday(t.orderReceived || t.product.orderReceived)).length,
          0,
        ),
    [usersEdges],
  );
  const tomorrowTasks = useMemo<number>(
    () =>
      usersEdges
        ?.map((e) => e.node)
        ?.reduce(
          (acc, cur) =>
            acc + cur.tasks.filter((t) => isScheduledTomorrow(t.orderReceived || t.product.orderReceived)).length,
          0,
        ),
    [usersEdges],
  );

  const users = useMemo<PlanningGraphicDesign_UserFragment[]>(() => {
    const usrs =
      usersEdges?.map((e) => {
        if (!shouldFilter(customFilter)) {
          return e.node;
        }

        return {
          ...e.node,
          tasks: filterTasks(e.node.tasks, customFilter),
        };
      }) || (emptyArray as PlanningGraphicDesign_UserFragment[]);

    const order = planningGraphicDesignUsersOrderSettings?.value || [];
    return usrs.sort((u1, u2) => {
      return order.indexOf(u1.id) - order.indexOf(u2.id);
    });
  }, [usersEdges, customFilter, planningGraphicDesignUsersOrderSettings]);

  const onTaskMove = useEventCallback(async (task: Task, userId: string, newPosition: number) => {
    const res = onMoveHelper(task, userId, newPosition, users);
    if (!res) {
      return;
    }

    const { position, tasks } = res;
    try {
      await taskMoveExecute({
        variables: {
          id: task.id,
          updatedAt: task.updatedAt,
          userId,
          position,
        },
        update: (cache, { data: { taskMove } }) => {
          const cached = cache.readQuery<PlanningGraphicDesign_LoadQuery, PlanningGraphicDesign_LoadQueryVariables>({
            query: PlanningGraphicDesign_Load,
          });

          cache.writeQuery<PlanningGraphicDesign_LoadQuery, PlanningGraphicDesign_LoadQueryVariables>({
            query: PlanningGraphicDesign_Load,
            data: {
              ...cached,
              users: {
                ...cached.users,
                edges: cached.users.edges.map((c) => ({
                  __typename: 'UserEdge',
                  node:
                    c.node.id === userId
                      ? {
                          ...c.node,
                          tasks: [
                            taskMove.find((t) => t.id === task.id), // Add new task to user
                            ...c.node.tasks.filter((t) => t.id !== task.id), // Filter current task
                          ].sort((a, b) => a.position - b.position), // Sort
                        }
                      : {
                          ...c.node,
                          tasks: [...c.node.tasks.filter((t) => t.id !== task.id)]
                            .map((t) => taskMove.find((u) => t.id === u.id) || t)
                            .sort((a, b) => a.position - b.position),
                        },
                })),
              },
            },
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          taskMove: tasks,
        },
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  const onColumnMove = useEventCallback((userId: string, position: number) => {
    const execute = async () => {
      const now = new Date().toISOString();
      try {
        const order = users.map((u) => u.id).filter((id) => id !== userId);
        order.splice(position, 0, userId);
        await saveColumnsExecute({
          variables: {
            order,
            updatedAt: planningGraphicDesignUsersOrderSettings?.updatedAt || now,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            settingsSave: {
              __typename: 'Settings',
              key: 'PLANNING_GRAPHIC_DESIGN_USERS_ORDER',
              updatedAt: now,
              value: order,
            },
          },
        });
      } catch (err) {
        logger.error(err);
      }
    };

    execute();
  });

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faPencilRuler} /> Plánovanie grafického štúdia
          </>
        }
        actions={
          <TasksFilter
            userSettingsKey="PlanningGraphicDesign__customFilter"
            todayTasksCount={todayTasks}
            tomorrowTasksCount={tomorrowTasks}
            disableBigFormatPrint={true}
            disableSmallFormatPrint={true}
            disableShappedPrePlotter={true}
          />
        }
      />

      {error || (loading && !data) ? (
        <Loading error={error} />
      ) : users.length > 0 ? (
        <KanbanBoard
          users={users}
          onTaskMove={onTaskMove}
          onColumnMove={canManageSettings ? onColumnMove : undefined}
          deadlineField="approvedAt"
          startedField="graphicDesignStartedAt"
        />
      ) : (
        <Box height="100%">
          <Box
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
            color="danger.main"
            flexDirection="column"
          >
            <Alert severity="error">
              <AlertTitle>Bez pracovníkov</AlertTitle>
              Najskôr pridajte do systému používateľov s rolou <strong>{Role.GraphicDesigner}</strong>.
            </Alert>
          </Box>
        </Box>
      )}
    </>
  );
};

export default authenticated()(GraphicDesign);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({ query: PlanningGraphicDesign_Load });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Plánovanie',
    },
    {
      text: 'Plánovanie grafického štúdia',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Plánovanie grafického štúdia | ${meta.title}`,
  }),
};
